<template>
  <div class="integral-page-view">
    <div class="main-cont">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/pointsMall' }">{{
          lang_data == 1 ? "积分商城" : "Integral mall"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          lang_data == 1 ? "积分明细" : "Integral subsidiary"
        }}</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="current-integral-panel mt20">
        <img src="~@/assets/img/Points mall-icon-integral-big 32.png" alt="" />
        <span class="ml10 mr10">{{
          lang_data == 1 ? "当前积分" : "The current integral"
        }}</span>
        <span class="ct2">{{ info ? info.doctor_info.integral : 0 }}</span>
      </div>
      <div class="integral-panel">
        <div class="integral-panel-title mb10">
          {{ lang_data == 1 ? "积分明细" : "Integral subsidiary" }}
        </div>
        <div class="integral-panel-group">
          <div class="integral-detail-tabs">
            <div
              class="tabitem"
              :class="{ checked: type == 0 }"
              @click="onTabClick(0)"
            >
              {{ lang_data == 1 ? "全部" : "All" }}
            </div>
            <div
              class="tabitem"
              :class="{ checked: type == 1 }"
              @click="onTabClick(1)"
            >
              {{ lang_data == 1 ? "已获取" : "Existing" }}
            </div>
            <div
              class="tabitem"
              :class="{ checked: type == -1 }"
              @click="onTabClick(-1)"
            >
              {{ lang_data == 1 ? "已使用" : "Used" }}
            </div>
          </div>
          <div
            v-loading="loading"
            :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
            style="min-height: 200px"
          >
            <div class="integral-desc-box">
              {{ lang_data == 1 ? "总获取积分" : "Total acquired integral" }}
              <b>+ {{ info ? info.doctor_info.history_total_integral : 0 }}</b
              >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              {{ lang_data == 1 ? "总使用积分" : "Total usage points" }}
              <b>- {{ info ? info.doctor_info.user_integral : 0 }}</b>
            </div>

            <div class="integral-record-list">
              <div
                class="integral-record-item"
                v-for="item in dataList"
                :key="item.id"
              >
                <div class="record-left">
                  <div>
                    <b>{{ item.msg }}</b>
                  </div>
                  <div class="exchange-time mt5">
                    {{
                      $moment(item.add_time * 1000).format("YYYY-MM-DD hh:mm")
                    }}
                  </div>
                </div>
                <div class="record-right">
                  <div :class="[item.integral * 1 >= 0 ? 'use' : 'get']">
                    {{ item.integral }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-empty
            v-if="dataList.length < 1 && !loading"
            :description="lang_data == 1 ? '暂无记录' : 'Has been used'"
          ></el-empty>

          <div class="page" v-if="dataList.length > 0">
            <el-pagination
              background
              layout="total,prev, pager, next"
              @current-change="changePage"
              :page-size="page.pageSize"
              :current-page="page.currentPage"
              :total="page.totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <div class="integral-panel" style="margin-top: 16px">
        <div class="integral-panel-title mb10">
          {{ lang_data == 1 ? "积分规则" : "Integral rules" }}
        </div>
        <div class="integral-panel-group">
          <div v-if="regularData" v-html="regularHtml"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      info: null,
      type: 0,
      dataList: [],

      page: {
        currentPage: 1,
        pageSize: 10,
        totalPage: 0,
        totalCount: 0,
      },

      regularData: null,
      loading: true,
    };
  },
  computed: {
    regularHtml() {
      if (!this.regularData) return "";
      return decodeURIComponent(this.regularData.integral_detail);
    },
  },
  mounted() {
    this.getUserInfo();
    this.getList();
    this.getRegular();
  },
  methods: {
    changePage(val) {
      this.getList(val);
    },
    onTabClick(type) {
      this.type = type;
      this.getList(1);
    },
    getUserInfo() {
      this.$axios.get("/ucenter/getinfo", {}).then((res) => {
        if (res.code == 1) {
          this.info = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList(current) {
      this.loading = true;
      this.$axios
        .get("/ucenter/exchange_integral_goods/getIntegralLog", {
          params: {
            current_page: current ? current : this.page.currentPage,
            page_size: this.page.pageSize,
            event: this.type,
          },
        })
        .then((res) => {
          if (res.code == 1) {
            this.dataList = res.data.list;
            this.page.currentPage = res.data.current_page;
            this.page.totalPage = res.data.total_page;
            this.page.totalCount = res.data.total_count;
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        });
    },
    getRegular() {
      this.$axios
        .get("/ucenter/integral_goods/getIntegralConfig")
        .then((res) => {
          if (res.code == 1) {
            this.regularData = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.integral-page-view {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: #fff;
  .main-cont {
    margin: 0 auto;
    padding: 20px 0 30px;
    width: 1300px;
    .current-integral-panel {
      height: 60px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background: linear-gradient(135deg, #00adff 0%, #409eff 100%);
      box-shadow: 0px 1px 5px 0px rgba(227, 228, 230, 0.48);
      padding: 0 20px;
      margin-bottom: 10px;
      color: #fff;
      .ct2 {
        font-size: 24px;
        font-weight: bold;
        margin-left: 8px;
      }
    }

    .integral-panel {
      background: #ffffff;
      box-shadow: 0 0 12px 0 #e4e4e4;
      border: 1px solid #e4e4e4;
      border-radius: 8px;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      .integral-panel-title {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        flex: none;
      }
      .integral-panel-group {
        .integral-detail-tabs {
          width: 100%;
          border-bottom: 1px solid #e1e1e5;
          display: flex;
          align-items: center;
          .tabitem {
            width: 117px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            &.checked {
              border-color: #009ae3;
              font-weight: bold;
            }
          }
        }
      }

      .integral-desc-box {
        padding: 10px 0;
        background: #f2f2f2;
        margin-top: 20px;
        padding-left: 20px;
        color: #409eff;
        border: 1px solid #efefef;
      }

      .integral-record-list {
        width: 100%;
        margin-top: 14px;
        .integral-record-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px;
          border-bottom: 1px solid #efefef;
          .record-right {
            .get {
              font-size: 16px;
              font-weight: bold;
            }
            .use {
              font-size: 16px;
              font-weight: bold;
              color: #409eff;
            }
          }
        }
      }
      .page {
        margin-top: 20px;
        text-align: right;
      }
    }
  }
}
</style>